const ROUTS = {
  GET_USER_DATA: 'PreRegistration/MemberPreReg',
  POST_MEMBER_DATA: 'PreRegistration/preRegistration',
  GET_CHURCHES: 'ChurchService/AllChurches',
  POST_PRE_REGISTRATION: 'PreRegistration/preRegistrationFormSubmit',
}

export class BackendlessService {
  /**
   * Get user data for form
   * return Promise
   * @param {*} userId number
   * @param {*} churchId number
   */
  static getUserData(userId, churchId) {
    return this.sendGETRequest(ROUTS.GET_USER_DATA, {userId, churchId});
  }

  /**
   * Get member data for form
   * return Promise
   * @param {*} memberId number
   *
   */
  static getMemberData(memberId) {
    return this.sendPOSTRequest(ROUTS.POST_MEMBER_DATA, memberId);
  }

  /**
   * Get churches list
   * return Promise
   * @param {*} options object
   */
  static getChurches(options) {
    return this.sendGETRequest(ROUTS.GET_CHURCHES, options);
  }

  /**
   * Post pre-registration information
   * return Promise
   * @param {*} data object
   */
  static registerMember(data) {
    return this.sendPOSTRequest(ROUTS.POST_PRE_REGISTRATION, data);
  }

  /**
   * send GET request
   * return Promise
   * @param {*} route string
   * @param {*} params object
   */
  static sendGETRequest(route, params) {
    const queryString = params ? '?' + new URLSearchParams(params).toString() : '';

    return fetch(`${process.env.REACT_APP_BACKENDLESS_URL}/api/services/${route}${queryString}`, {
      method: 'GET',
      headers: {
        "Accept": "application/json",
        "Content-type": "application/json",
      },
    })
    .then((response) => response.json())
  }

  /**
   * send POST request
   * return Promise
   * @param {*} route string
   * @param {*} body object
   */
  static sendPOSTRequest(route, body) {
    return fetch(`${process.env.REACT_APP_BACKENDLESS_URL}/api/services/${route}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        "Accept": "application/json",
        "Content-type": "application/json",
      },
    })
    .then((response) => response.json())
  }
}
