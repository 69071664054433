import React from 'react';
import './PreRegistration.scss';
import { Navigate } from 'react-router-dom';
import { Snackbar, Alert } from '@mui/material';
import { BackendlessService } from '../../core/services/backendless.service';
import PreRegistrationForm from './PreRegistrationForm';

export class PreRegistration extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      member: null,
      navigate: false,
      stateAfterSubmit: null,
      annMeetingReg: false,
      error: null,
    };
  }

  componentDidMount() {
    const urlParams = this.getUrlParams();
    if (urlParams?.userID && urlParams?.churchID) {
      this.getUserData(urlParams.userID, urlParams.churchID);
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  getUrlParams() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return Object.fromEntries(urlSearchParams.entries());
  }

  getUserData(userId, churchId) {
    BackendlessService.getUserData(userId, churchId)
      .then(res => {
        if (res.memberId) {
          if (res.user) {
            this.setState({
              annMeetingReg: !!res.user.annMeetingReg,
            });
          }
          this.getMemberData(res.memberId);
        } else {
          this.setState({
            loading: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  getMemberData(memberId) {
    BackendlessService.getMemberData(memberId)
      .then(res => {
        const member = {...res};
        if (member.phone.includes('+1')) {
          member.phone = member.phone.replace('+1', '');
        };
        this.setState({
          loading: false,
          member: member,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  registerMember(data, churchName, role) {
    BackendlessService.registerMember(data)
      .then(res => {
        this.setState({
          stateAfterSubmit: {churchName, role},
          loading: false,
          navigate: true,
        });
      })
      .catch((e) => {
        console.error(e);
        this.setState({
          loading: false,
          error: e.message,
        });
      });
  }

  onSubmit(data) {
    const preparedData = {
      churchId: data.churchName.churchId,
      email: data.email,
      firstname: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      role: parseInt(data.role),
    };
    this.registerMember(preparedData, data.churchName.name, data.role);
  }

  handlePopupClose = () => {
    this.setState({
      error: null
    });
  };

  render() {
    return (
      <section className="pre-registration"> 
        <header className="pre-registration-header">
          <h2 className="pre-registration-header-title">
            {new Date().getFullYear()} BGAV Annual Meeting
          </h2>
        </header>
        { !this.state.loading ? 
        <PreRegistrationForm 
          member={this.state.member} 
          annMeetingReg={this.state.annMeetingReg}
          onSubmit={(data) => this.onSubmit(data)}/> 
          : ''}

        {this.state.navigate ? 
        <Navigate 
          to="/success-registration" 
          state={this.state.stateAfterSubmit}/> : ''} 

        <Snackbar 
          open={!!this.state.error} 
          autoHideDuration={6000} 
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }} 
          onClose={this.handlePopupClose}>
          <Alert severity="error">{this.state.error}</Alert>
        </Snackbar>
      
      </section>
    ); 
  }
}

