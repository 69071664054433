import { Button } from '@mui/material';
import './SuccessRegistration.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';

function SuccessRegistration(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState(() => {
    const data = location.state;
    const role = {
      6: 'Guest',
      7: 'Messenger',
      8: 'Staff',
      9: 'Exhibitor',
    };
    return data ? {
      role: role[data.role],
      churchName: data.churchName,
    } : {}
  });

  const navigateToStart = () => {
    if (window.webkit) {
      window.webkit.messageHandlers?.successfullyPreregistered?.postMessage({message: 'test'});
    }; 
    /*global SuccessfullyPreregisteredInterface*/
    /*eslint no-undef: ["error", { "typeof": true }] */
    try {
      SuccessfullyPreregisteredInterface?.successfullyPreregistered();
    } catch (error) {
      
    }
    
    navigate('/pre-registration', { replace: true });
  }

  return (
    <section className="success-registration"> 
      <header className="success-registration-header">
        <h2 className="success-registration-header-title">
          {new Date().getFullYear()} BGAV Annual Meeting
        </h2>
      </header>
      <div className="success-registration-content">
        <p className="success-registration-content-main-text">
          You are pre-registered as {state.role} from {state.churchName} for the {new Date().getFullYear()} Annual Meeting
        </p>
        <p className="success-registration-content-secondary-text">
          If you are on the app, click below to return to the meeting page, 
          if you registered by other means you may now close this page
        </p>
        <Button className="success-registration-content-btn" onClick={navigateToStart}>
          Return to main 
        </Button>
      </div>
    </section>
  );  
}

export default SuccessRegistration;
