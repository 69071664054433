import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PreRegistration } from '../../modules/pre-registration/PreRegistration';
import SuccessRegistration from '../../modules/success-registration/SuccessRegistration';

const publicRoutes = [
  // {path: '/home', component: <HomePage />},
  {path: '/pre-registration', component: <PreRegistration />},
  {path: '/success-registration', component: <SuccessRegistration />},
];

function AppRouter() {
  const urlParams = Object.fromEntries(new URLSearchParams(window.location.search));
  let preRegistrationRoute = "/pre-registration";
  if (urlParams?.userID && urlParams?.churchID) {
    preRegistrationRoute = `/pre-registration?userID=${urlParams.userID}&churchID=${urlParams.churchID}`;
  } 
  
  return (
    <Routes>
      {
        publicRoutes.map(
          (route) => <Route path={route.path} element={route.component} key={route.path}/>,
        )
      }
      <Route
        path="*"
        element={<Navigate to={preRegistrationRoute} replace={true}/>}
      />
    </Routes>
  );
}

export default AppRouter;
