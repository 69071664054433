import React, { useState, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import './PreRegistration.scss';
import { 
  Button, 
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  Autocomplete,
} from '@mui/material';
import { BackendlessService } from '../../core/services/backendless.service';
import debounce from 'lodash.debounce';

function PreRegistrationForm(props) {
  const regexName = new RegExp(/^[a-zA-Z-_'`\s]+$/i);
  const regexPhone = new RegExp(/^[0-9]+$/i);

  const getChurches = (options) => {
    BackendlessService.getChurches(options)
      .then(res => {
        setChurchOptions(res);
      })
      .catch(() => {
      });
  }

  const changeHandler = options => {
    getChurches(options);
  };

  const getChurchesWithDelay = useCallback(
    debounce(changeHandler, 600)
  , []);

  const onSearchChange = (e) => {
    const value = e.target.value;

    if (value !== requestOptions.q) {
      setRequestOptions(
        {
          limit: 50,
          offset: 0,
          q: value,
        }
      );
      getChurchesWithDelay({
        limit: 50,
        offset: 0,
        q: value,
      });
    }
  }

  const [requestOptions, setRequestOptions] = useState({
    limit: 50,
    offset: 0,
    q: '',
  });
  const [churchOptions, setChurchOptions] = useState([]);

  useEffect(() => {
    getChurches({
      limit: 50,
      offset: 0,
      q: '',
    });
  }, []);

  yup.addMethod(yup.string, "matchMessageCode", function (errorMessage) {
    return this.test(`test-church-uuid`, errorMessage, function (value) {
      const { path, createError } = this;

      if (formik.values.role !== "7") {
        return true
      }

      if (value && formik.values.churchName && formik.values.churchName.uuid === value) {
        return true;
      }
  
      return createError({ path, message: errorMessage })
    });
  });

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .required('This field is required'),
    lastName: yup
      .string()
      .required('This field is required'),
    phone: yup
      .string()
      .length(10, 'Please enter a Valid Mobile Number')
      .matches(regexPhone ,'Please enter a Valid Mobile Number')
      .required('This field is required'),
    churchName: yup
      .mixed()
      .required('This field is required'),
    email: yup
      .string()
      .min(6, 'Email must be more than 6 characters')
      .max(254, 'Email must be less than 254 characters')
      .email('Please enter a valid email address')
      .required('This field is required'),
    role: yup
      .string()
      .required('This field is required'),
    churchMessengerCode: yup
      .string()
      .matchMessageCode('Please enter the correct messenger code or select a different role type to continue'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: props.member ? props.member.firstName : '',
      lastName: props.member ? props.member.lastName : '',
      phone: props.member ? props.member.phone : '',
      churchName: props.member ? {
        name: props.member.name,
        uuid: props.member.uuid,
        churchId: props.member.churchId,
      } : '',
      email: props.member ? props.member.email : '',
      role: '',
      churchMessengerCode: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (value) => {
      props.onSubmit(value)
    },
  });

  const [disabledFields, setDisabledFields] = useState({
    firstName: false,
    lastName: false,
    phone: props.member && props.annMeetingReg ? !!props.member.phone : false,
    churchName: false,
    email: false,
    role: false,
    churchMessengerCode: false,
  });
  
  return (
    <div className="pre-registration-content">
      <form 
        className="pre-registration-content-form" 
        autoComplete="off" 
        onSubmit={formik.handleSubmit}
      >
        <FormControl 
          variant="standard" 
          className={`
            form-control 
            ${formik.touched.firstName && Boolean(formik.errors.firstName) ? 'error' : ''}`}
        >
          <TextField
            className="text-field"
            fullWidth
            id="firstName"
            name="firstName"
            label="First name"
            disabled={disabledFields.firstName}
            variant="standard"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
        </FormControl>

        <FormControl 
          variant="standard" 
          className={`
            form-control 
            ${formik.touched.lastName && Boolean(formik.errors.lastName) ? 'error' : ''}`}
        >
          <TextField
            className="text-field"
            fullWidth
            id="lastName"
            name="lastName"
            disabled={disabledFields.lastName}
            label="Last name"
            variant="standard"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
        </FormControl>

        <FormControl 
          variant="standard" 
          className={`
            form-control 
            ${formik.touched.phone && Boolean(formik.errors.phone) ? 'error' : ''}`}
        >
          <TextField
            className="text-field"
            fullWidth
            id="phone"
            name="phone"
            disabled={disabledFields.phone}
            label="Phone number"
            variant="standard"
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
          />
        </FormControl>

        <FormControl 
          variant="standard" 
          className={`
            form-control 
            select-control
            autocomplete-control
            ${formik.touched.churchName && Boolean(formik.errors.churchName) ? 'error' : ''}`}
        >
          <Autocomplete  
            selectOnFocus
            id="churchName"
            name="churchName"
            variant="standard"
            disabled={disabledFields.churchName}
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                formik.setFieldValue('churchName', {name: newValue});
              } else if (newValue && newValue.inputValue) {
                formik.setFieldValue('churchName', {name: newValue});
              } else {
                formik.setFieldValue('churchName', newValue);
              }
              
            }}
            value={formik.values.churchName}
            options={churchOptions}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.name;
            }}
            filterOptions={(options, params) => options}
            renderOption={(props, option) => <li className="select-item" {...props}>{option.name}</li>}
            renderInput={(params) => (
              <TextField 
                {...params} 
                onChange={(options, params) => onSearchChange(options, params)}
                className="text-field" 
                label="Church name"
                variant="standard"
                error={formik.touched.churchName && Boolean(formik.errors.churchName)}
              />
            )}
          />
          <FormHelperText
            error={formik.touched.churchName && Boolean(formik.errors.churchName)}
          >
            {formik.touched.churchName && formik.errors.churchName}
          </FormHelperText>
        </FormControl>

        <FormControl 
          variant="standard" 
          className={`
            form-control 
            ${formik.touched.email && Boolean(formik.errors.email) ? 'error' : ''}`}
        >
          <TextField
            className="text-field"
            fullWidth
            id="email"
            name="email"
            disabled={disabledFields.email}
            label="Email"
            variant="standard"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </FormControl>

        <FormControl 
          variant="standard" 
          className={`
            form-control 
            select-control
            ${formik.touched.role && Boolean(formik.errors.role) ? 'error' : ''}`}
        >
          <div className="select-control-root">
            <InputLabel 
              id="registration-type-label" 
              className="select-label"
              error={formik.touched.role && Boolean(formik.errors.role)}
            >
              Select registration type
            </InputLabel>
            <Select
              className="select-container"
              labelId="registration-type-label"
              id="role"
              name="role"
              label="Select registration type"
              aria-describedby="my-helper-text"
              value={formik.values.role}
              onChange={formik.handleChange}
              error={formik.touched.role && Boolean(formik.errors.role)}
            >
              <MenuItem className="select-item" value="6">Guest</MenuItem>
              <MenuItem className="select-item" value="7">Messenger</MenuItem>
              <MenuItem className="select-item" value="8"> Staff</MenuItem>
              <MenuItem className="select-item" value="9">Exhibitor</MenuItem>
              <MenuItem className="select-item" value="10">Virtual Attendee</MenuItem>
            </Select>
            <FormHelperText
              error={formik.touched.role && Boolean(formik.errors.role)}
            >
              {formik.touched.role && formik.errors.role}
            </FormHelperText>
          </div>
        </FormControl>

        { formik.values.role === "7" ?
          <FormControl 
            variant="standard" 
            className={`
              form-control 
              ${formik.touched.churchMessengerCode && Boolean(formik.errors.churchMessengerCode) ? 'error' : ''}`}
          >
            <TextField
              className="text-field"
              fullWidth
              id="churchMessengerCode"
              name="churchMessengerCode"
              label="Church Messenger Code"
              variant="standard"
              value={formik.values.churchMessengerCode}
              onChange={formik.handleChange}
              error={formik.touched.churchMessengerCode && Boolean(formik.errors.churchMessengerCode)}
              helperText={formik.touched.churchMessengerCode && formik.errors.churchMessengerCode}
            />
          </FormControl> : ''
        }

        <Button className="submit-btn" type="submit">
          Pre-register
        </Button>
        
      </form>
      
    </div>
  );  

}

export default PreRegistrationForm;
